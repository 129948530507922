<template>
    <div class="instruction-detail">
        <div class="carousel-topImg">
            <img src="../assets/images/top.png" alt="top" class="img-fluid">
        </div>
        <div class="container">
            <div class="container-content conatiner-carousel instruction-page">
                <div class="carousel-heading test-voice">
                    <h2>Test Voice</h2>
                    <h5>You should check the <br> microphone before you start</h5>
                </div>
                <div class="instruc-img">
                    <img src="../assets/images/test-voice.png" alt="test-voice" class="img-fluid mt-3">
                    <img src="../assets/images/waves.png" alt="waves" class="img-fluid mt-3" style="height:100px !important;">
                    <div class="instuction-play mt-4">
                        <img src="../assets/images/play-circle.png" alt="play-circle" class="play-circle mr-3"     @click="startRecognition()"  >
                        <p>Test Audio  Say "Hello World"</p>
                        <span><Loader :show-loader="isDataLoading" :isFullPage="false" :height="20" /> </span>
                        <!-- <customspeech /> -->
                         <span v-if="showtext">
                          <p v-for="(word, key) in transcription" :key="key">{{ word }}</p>
                          <p>{{ runtimeTranscription }}</p>
                          <!-- <button type="button" @click="startRecognition()"> play button </button> -->
                        </span>
                        <!-- <vue-speech-new /> -->
                    </div>

                    <!-- <div class="int-btn">
                        <button>Start Interview</button>
                    </div> -->
                </div>
                <div>
            </div>
        </div>
    </div>
        <!-- <div class="overlay">
            <span class="overlay-content"></span>
            <div class="text">
                <div class="got">got it</div>
                <div class="dot"><span>.</span>..</div>
                <div class="next">next</div>
            </div>
          </div> -->
    </div>
</template>

<script lang="ts">
import { mapGetters, mapActions, mapState } from "vuex";
import Loader from "@/components/Loader";
import { verifySpeech } from "@/constants/consts";

// import customspeech from '@/components/customspeech.vue'

// export default {
//      name: "instru",
//      computed: {
//       ...mapState(["questionsVideo"]),
//       ...mapGetters(["doneInstructions",'loggedInUser'])
//     },
//     beforeMount() {
//     //   if (this.doneInstructions) {
//     //     this.$router.push('/check-permission')
//     //   }
//     },
//     methods: {
//     //   setInstruction2() {
//     //     console.log('setInstructionState')
//     //     this.$router.push('/instruction')

//     //   }
//     }
// }

export default {
  name: 'vue-speech',
   props: {
    lang: {
      type: String,
      default: 'en-US'
    }
  },
  data: () => ({
    verifySpeechText: verifySpeech,
    runtimeTranscription: '',
    transcription: [],
    recognition: null,
    showtext:false,
    isMatched:false,
    isDataLoading:true
  }),
  watch: {
    // async appLivestatus(value, oldValue) {
    //   if (value == "hidden") {
    //     this.stopTimer = true;
    //   }

    //   if (value == "visible" && this.questionDetail) {
    //     this.stopTimer = false;
    //     await this.skipQuestion("system_skipped");
    //   }
    // }
  },
  components: {
    // customspeech
    Loader,
    //  'vue-speech-new': speech
  },
  methods: {
    startRecognition() {
      this.isMatched = false
      console.log('Starting');
      this.checkApi();
      this.recognition.start();
    },
    stopRecognition() {
      console.log("Stopping");
      this.recognition.stop();
      this.recognition = null;
      this.transcription = [],
      this.runtimeTranscription = ''
    },
    checkApi() {
      window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      if (!SpeechRecognition && "development" !== 'production') {
         this.$store.commit('setInstruction', true)
            this.$router.push('/check-permission')
            console.log('Speech Recognition does not exist on this browser. Use Chrome or Firefox')
        throw new Error('Speech Recognition does not exist on this browser. Use Chrome or Firefox');
       
      }
      if (!SpeechRecognition) {
        console.log("No Speech Recognition");
        return;
      }
      console.log("Starting UP");
      this.recognition = new SpeechRecognition();

      this.recognition.lang = this.lang;
      this.recognition.interimResults = true;
      this.isDataLoading = true
      this.showtext = true

      this.recognition.addEventListener('result', event => {
        console.log("result");
        const text = Array.from(event.results).map(result => result[0]).map(result => result.transcript).join('');
        this.runtimeTranscription = text;
      });
      const vm = this
      this.recognition.addEventListener('end', () => {
        console.log("End");
        if(vm.isMatched == false) {
          vm.$toast.open({
            message:"Your voice is not matched, please try again, To record voice click on play button",
            type: "error"
          });
        }

        if (this.runtimeTranscription !== '') {
          console.log('this.runtimeTranscription .... ',this.runtimeTranscription)
          this.transcription.push(this.runtimeTranscription);
          this.$emit('onTranscriptionEnd', {
            transcription: this.transcription,
            lastSentence: this.runtimeTranscription
          });

          this.showtext = false
          this.isDataLoading = false
        } else {
          console.log("Nothing Found");
        }
        this.runtimeTranscription = '';
      });

      this.recognition.onresult = function(event) {
        var color = event.results[0][0].transcript;
        // console.log('color',color);
        console.log('transcription',vm.transcription);
        console.log(' vm.isMatched',  vm.isMatched)
         const incStr = String(vm.transcription).includes(vm.verifySpeechText);
         console.log('incStr ',incStr)
         if((color  == vm.verifySpeechText || incStr == true) && vm.isMatched == false) {
           vm.isMatched = true
           console.log('matched.....................................')
            vm.$toast.open({
                message:"Your voice matched successfully.",
                type: "success"
              });
            vm.stopRecognition()
            vm.$store.commit('setInstruction', true)
            vm.$router.push('/check-permission')
        }
      }
    }
  },
  mounted() {
    this.checkApi();
    // this.startRecognition()
  },
   async created() {
    var context = new AudioContext();
    this.context = context;

    // // One-liner to resume playback when user interacted with the page.
    // // document.querySelector('button').addEventListener('click', function() {
    if (this.context.state == "suspended") {

      // const that = this;
      // navigator.getUserMedia(
      //   {
      //     audio: true
      //   },
      //   function(stream) {
      //     // that.startUserMedia(stream)
      //     const input = audio_context.createMediaStreamSource(stream);
      //   },
      //   function(e) {
      //     console.log("No live audio input: " + e);
      //   }
      // );
      await this.context.resume().then(() => {
        console.log("Playback resumed successfully");
      });
    }
     await this.context.resume().then(() => {
        console.log("Playback resumed successfully");
      });
      // this.checkApi()
      // console.log('.........................')
    // });
    // try {
    //   window.AudioContext = window.AudioContext || window.webkitAudioContext;
    //   navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia;
    //   window.URL = window.URL || window.webkitURL;
    //   console.log(   window.AudioContext )
    //   audio_context = new AudioContext;
    //   console.log('Audio context set up.');
    //   console.log('navigator.getUserMedia ' + (navigator.getUserMedia ? 'available.' : 'not present!'));
    // } catch (e) {
    //   alert('No web audio support in this browser!');
    // }
    // const that = this;
    // navigator.getUserMedia({
    //   audio: true
    // }, function(stream) {
    //   that.startUserMedia(stream)
    // }, function(e) {
    //   console.log('No live audio input: ' + e);
    // });
  },
}
</script>